@import 'base';
@import 'helpers';

.hero {
  max-width: 2000px;
  margin: 0 auto;
  margin-bottom: -20%;

  &__image {
    width: 100%;
    height: auto;
  }

  @include bp-mobile() {
    margin-bottom: -5rem;
  }
}

.copyright {
  text-align: center;
  font-size: 0.8rem;
  padding: 1rem 0;
}