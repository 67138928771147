@import "../helpers";

.release {
  max-width: 45rem;
  margin: 2rem auto;

  @include bp-mobile() {
    margin: 1rem auto;
  }

  &__image {
    margin: 1rem auto;
    height: auto;
    width: 400px;

    @include bp-mobile() {
      width: 250px;
    }
  }

  &__description {
    white-space: pre-wrap;
  }
}