@import 'helpers';

html {
  font-size: 18px;
  line-height: 1.4;
  scroll-behavior: smooth;

  @include bp-mobile() {
    font-size: 15px;
  }
}

body {
  --light-color: #FFAF56;
  --dark-color: #3E2262;
  --bg-color: var(--light-color);
  --text-color: var(--dark-color);
  --gutter: 3rem;
  --logo-width: 20rem;
  font-family: 'Space Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  color: var(--text-color);
  background-color: var(--bg-color);
  min-width: 320px;

  @include bp-mobile {
    --gutter: 2rem;
    --logo-width: 10rem;
  }
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

i {
  font-style: normal;
  font-family: monospace;
  font-size: 1.1rem;
}

// style p in lead due to richtext editor
.lead p {
  font-size: 1.3rem;
}

.hr {
  border-top: 1px solid var(--text-color);

  border-bottom: 0;
  margin: 0 var(--gutter);
}

.small-hr {
  &::after {
    content: '';
    display: block;
    margin: 2rem auto;
    width: 50px;
    border-bottom: solid 5px var(--text-color);
  }
}

.cta-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cta {
  display: inline-block;
  color: var(--bg-color);
  background-color: var(--text-color);

  text-decoration: none;
  font-size: 0.8rem;
  padding: 0.5rem 1.5rem;
  margin: 0.4rem 0;

  &:hover {
    text-decoration: underline;
  }
}

.headline {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  margin: 0;

  &--small {
    font-size: 2rem;

    @include bp-mobile() {
      font-size: 1rem;
    }
  }

  &--medium {
    font-size: 3rem;

    @include bp-mobile() {
      font-size: 2rem;
    }
  }

  &--large {
    font-size: 6rem;
    line-height: 0.9;

    @include bp-mobile() {
      font-size: 3.5rem;
    }
  }
}

.centered {
  text-align: center;
}

.container {
  color: var(--text-color);
  background-color: var(--bg-color);

  &--top {}

  &--middle {
    --bg-color: var(--dark-color);
    --text-color: var(--light-color);

    background-image: linear-gradient(var(--text-color), var(--bg-color));
  }

  &--bottom {
    --bg-color: var(--dark-color);
    --text-color: var(--light-color);
  }
}

.content {
  padding: var(--gutter);
  max-width: 1400px;
  margin: 0 auto;

  &--dual {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__col {
    width: calc(50% - 2rem);

    @include bp-mobile() {
      width: 100%;
      margin-top: 1rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}