@import '../helpers';

.contact {
  padding: 5rem 3rem;

  @include bp-mobile() {
    padding: 3rem 2rem;
  }

  // add headline for specificity
  // override margin from headline
  .headline {
    margin-bottom: 1rem;
  }
}